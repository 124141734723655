import React from 'react'
import { Link } from 'gatsby'
import classnames from 'classnames'

import Api from '../svg/api.svg'

import Brandwidget from '../svg/brand-widget.svg'
import Productwidget from '../svg/product-widget.svg'

export default class Widget extends React.Component {

    state = {
        target: 'brandwidget'
    }

    render() {

        const { target } = this.state

        return (
            <section className="text-center grey">
                <div className="inner">
                    <div className="text">
                        <h2>Makkelijke integratie in marketingkanalen</h2>
                        <p>Consumenten zien uw producten via online advertenties, sociale media-uitingen en de website van uw merk. Speel direct in op de koopbereidheid of interesse van de consument door direct te laten zien waar het product in de buurt verkrijgbaar is. Dit kan zowel op merk- als productniveau met behulp van verschillende widgets. De implementatie daarvan bestaat uit slechts &eacute;&eacute;n regel HTML-code die eenvoudig toegevoegd kan worden.</p>

                        <div className="tabs">
                            <ul>
                                <li className={classnames({ active: target === 'brandwidget'})}>
                                    <button onClick={() => this.setState({ target: 'brandwidget' })}>
                                        Merkwidget
                                    </button>
                                </li>
                                <li className={classnames({ active: target === 'productwidget'})}>
                                    <button onClick={() => this.setState({ target: 'productwidget' })}>
                                        Productwidget
                                    </button>
                                </li>
                            </ul>
                        </div>

                      {target === 'brandwidget' && (
                          <div className="widget brand-widget">
                              <Brandwidget />
                              <div className="widget-content">
                                  <h3>Merkwidget</h3>
                                  <p>De merkwidget (of store locator) toont welke verkooplocaties in de buurt uw merk verkopen.</p>
                              </div>
                          </div>
                      )}

                      {target === 'productwidget' && (
                          <div className="widget product-widget">
                              <Productwidget />
                              <div className="widget-content">
                                  <h3>Productwidget</h3>
                                  <p>De productwidget toont welke verkooplocaties in de buurt een specifiek product verkopen.</p>
                              </div>
                          </div>
                      )}

                      <div className="widget-statistics">
                          <Api />
                          <p><strong>Voor developers</strong>: De basis van de Zupr infrastructuur is de API. Hiermee kan in beide richtingen gekoppeld worden om bijvoorbeeld productinformatie vanuit uw PIM systeem automatisch te synchroniseren of productaanvragen af te handelen. Neem contact op voor een overzicht van de mogelijkheden. <Link to="/nl/datakoppeling/">Lees meer</Link></p>
                      </div>

                    </div>
                </div>
          </section>
        )
    }
}
