import React from 'react'
import { Helmet } from 'react-helmet'
// import { Link } from 'gatsby'

import SalesBoost from '../../components/contact-boost'
import Widgets from '../../components/widgets'
import Layout from '../../layout/layout'
import WatchVideo from '../../components/watch-video'

import Brand from '../../svg/brand-new.svg'
import Extendassortment from '../../svg/extend-assortment.svg'
import Locationzoom from '../../svg/location-zoom.svg'
import Frontoffice from '../../svg/frontoffice.svg'
import Statistics from '../../svg/statistics.svg'

const Page = () => (

    <Layout>

        <Helmet>
          <title>Merken - Van clicks naar bricks</title>
          <meta name="description" content="Met Zupr laat u consumenten online zien waar producten in lokale, fysieke winkels verkrijgbaar zijn." />
        </Helmet>

        <section className="city retailer">
            <h1>Uw lokale verkooppunten in kaart</h1>
            <WatchVideo id="4S_tWVxrAzQ">
                Bekijk video
            </WatchVideo>
            <a className="btn btn-demo" href="mailto:demo@zupr.io?subject=Ik wil graag een demo">Vraag demo aan</a>
            <div className="background">
                <Brand />
            </div>
        </section>

        <section className="text-center divider">
            <div className="inner">

                <div className="text">
                    <h2>Van click naar brick</h2>
                    <p>Consumenten zoeken online steeds gerichter op merk en product. Met Zupr toont u consumenten niet alleen waar uw merk in de buurt wordt verkocht, maar ook welke producten een verkooplocatie in zijn assortiment heeft. Zo bereikt u veel efficiënter de consument die zich online oriënteert, maar liever offline shopt.</p>
                </div>
            </div>

        </section>

        <section className="text-center">
            <div className="inner">

                <div className="text">
                    <h2>Al uw producten lokaal verkrijgbaar</h2>
                    <p>De verkoop van uw assortiment via fysieke winkels hoeft niet langer gelimiteerd te worden door de beperkte schapruimte van retailers. Zupr biedt namelijk functionaliteit waarmee door u geselecteerde retailers uw volledige assortiment kunnen leveren op aanvraag van de consument. Met dit digitale schap geeft u retailers toegang tot de begeerde ‘long tail’ van producten die tot voor kort alleen voor online partijen haalbaar was.</p>
                </div>

                <div className="image">
                    <Extendassortment />
                </div>

            </div>
        </section>

        <section className="text-left divider">
            <div className="inner">

                <div className="text">
                    <h3>Belangrijke voordelen van deze functionaliteit:</h3>
                    <ul className="check-list">
                        <li>Klanten krijgen de mogelijkheid uw producten aan te schaffen via een lokale, vertrouwde retailer van hun keuze. Dit heeft een positief effect op de conversie.</li>
                        <li>Producten kunnen altijd afgeleverd worden bij de retailer en de consument bepaalt zelf het gewenste moment van bezoeken van de winkel.</li>
                        <li>Verkopen worden afgehandeld in het bijzijn van een productspecialist. Dit verhoogt de servicegerichtheid richting klanten en komt de reputatie van uw merk ten goede.</li>
                        <li>Het Zupr-platform automatiseert het volledige proces en de onderlinge communicatie tussen de betrokken partijen.</li>
                    </ul>
                </div>

                <div className="image">
                    <Locationzoom />
                </div>

            </div>
        </section>

        <section className="text-left">
            <div className="inner">

                <div className="text">
                    <h2>Verbeter de relatie met retailers</h2>
                    <p>Heeft uw merk een goed bezochte website? Dan is een eigen webshop een logische keuze. Dit kan mogelijk conflicteren met de belangen van uw fysieke dealernetwerk.</p>
                    <p>U kunt echter klanten actief doorverwijzen naar uw retailers door Zupr te integreren in uw website. Daardoor kunt u traffic op uw website toch converteren en tegelijkertijd uw bestaande verkooplocaties meer aan u binden. Ook uw online marketing campagnes kunt u hiermee verrijken.</p>
                    <p>Is het gebruik van Zupr complex? Absoluut niet. We leggen u graag in ​<a href="mailto:demo@zupr.io?subject=Ik wil graag een demo">een demo​</a> uit hoe het werkt.</p>
                </div>

                <div className="image">
                    <Frontoffice />
                </div>

            </div>
        </section>

        <Widgets />

        <section className="text-center">
            <div className="inner">

                <div className="text">
                    <h2>Inzicht in lokale vraag en consumentengedrag</h2>
                    <p>Met Zupr kunt u beschikken over uitgebreide statistieken zoals het lokale zoekgedrag van- en interacties met consumenten. Heatmaps en insights op productniveau ondersteunen u als merk om uw assortiment optimaal te laten aansluiten op lokale vraag. Alle clicks van gebruikers worden anoniem geregistreerd en zo wordt onder andere inzichtelijk hoe vaak een product wordt bekeken, welke verkooplocaties worden aangeklikt of geraadpleegd en worden details uitgeklapt. Data zijn segmenteerbaar per tijdsperiode.</p>
                </div>

                <div className="image no-margin">
                    <Statistics />
                </div>

            </div>
        </section>

        <SalesBoost />

    </Layout>
)

export default Page
