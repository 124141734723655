import React from 'react'
// import { Link } from 'gatsby'

export default () => (
  <section className="contact-boost">
      <div className="inner">
          <h2>Heeft u vragen?</h2>
          <p>Voor vragen of toelichting kunt u contact opnemen met het Zupr team.</p>
          <p>
              <a href="mailto:support@zupr.nl?subject=Vraag over Zupr" className="email">support@zupr.nl</a>
              <a href="tel:+31854011306" className="telephone">+31 (0)85 40 11 306</a>
          </p>
      </div>
  </section>
)
