import React from "react";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import { StaticQuery, graphql } from "gatsby";

import Header from "../components/header";
import Footer from "../components/footer";
import Consent from "../components/consent";

import "../scss/section.scss";
import "../scss/layout.scss";
import "../scss/footer.scss";

// Sections
import "../scss/sections/city.scss";
import "../scss/sections/widgets.scss";
import "../scss/sections/apply-form.scss";
import "../scss/sections/contact-boost.scss";
import "../scss/sections/sales-boost.scss";
import "../scss/sections/target-groups.scss";
import "../scss/sections/zupr-customers.scss";
import "../scss/sections/research-and-graphs.scss";
import "../scss/sections/hand.scss";
import "../scss/sections/discover.scss";

// Visuals
import "../scss/visuals/about.scss";
import "../scss/visuals/flow.scss";

const Layout = ({ children, hasStickyElement, headerAndFooter }) => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `}
        render={(data) => (
            <div
                className={classnames("layout", {
                    "has-sticky-element": hasStickyElement,
                })}
            >
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{data.site.siteMetadata.title}</title>
                    <meta
                        name="description"
                        content="Met Zupr laat u consumenten online zien waar producten in lokale, fysieke winkels verkrijgbaar zijn."
                    />
                    <meta
                        name="keywords"
                        content="zupr, producenten, merken, verkooplocaties, locaties, verkooppunten, retail, retailers, producten, leveranciers, tussenhandelaren, groothandels, verkrijgbaarheid, beschikbaarheid, waar te koop"
                    />
                    <meta name="author" content="Zupr B.V." />
                    <meta property="og:url" content="https://www.zupr.io/" />
                    <meta
                        property="og:title"
                        content={data.site.siteMetadata.title}
                    />
                    <meta property="og:type" content="website" />
                    <meta
                        property="og:description"
                        content="Met Zupr laat u consumenten online zien waar producten in lokale, fysieke winkels verkrijgbaar zijn."
                    />
                    <meta
                        property="og:image:url"
                        content="https://www.zupr-cdn.services/static/zupr-social.png"
                    />
                    <meta property="og:image:type" content="image/png" />
                    <meta property="og:image:width" content="400" />
                    <meta property="og:image:height" content="400" />
                    <meta property="og:image:alt" content="Zupr" />
                    <meta property="fb:app_id" content="618986758307663" />
                </Helmet>

                <Consent />

                {headerAndFooter && (
                    <Header
                        hasStickyElement={hasStickyElement}
                        siteTitle={data.site.siteMetadata.title}
                    />
                )}

                <div className="container">{children}</div>

                {headerAndFooter && <Footer />}
            </div>
        )}
    />
);

Layout.defaultProps = {
    headerAndFooter: true,
};

export default Layout;
